.mat-mdc-dialog-container {
  @apply cbp-p-0 #{!important};

  .mat-mdc-dialog-surface,
  .mdc-dialog__surface {
    @apply cbp-shadow-none cbp-p-6 cbp-rounded-sm;
  }

  .separator {
    @apply cbp-my-4 cbp-border-0 cbp-border-t;
    @apply cbp--ml-6 cbp--mr-6 #{!important};;
  }
}

@media (max-width: 640px) {
  .calenso-dialog-box {
    @apply cbp-max-w-[100vw] #{!important};

    .mat-mdc-dialog-container {
      @apply cbp-max-h-[100vh] cbp-min-h-[100vh] cbp-p-0 #{!important};
    }
  }
}
