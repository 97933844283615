@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./app/styles/fonts.scss";
@import "./app/styles/variable";
@import "./app/styles/dialog.scss";

@layer base {
  *[style]:not(.calenso-booking > *) {
    @apply cbp-font-sans cbp-text-calenso-font-color;
  }
  :is(.material-icons, .material-icons span) {
    @apply cbp-font-materialIcons;
  }

  a {
    @apply cbp-text-calenso-neon-blue-color cbp-no-underline cbp-cursor-pointer;
  }

  .cbcp-event-description *,
  .cbcp-conference-description * {
    all: revert;
  }
}

@layer components {
  .partner-card {
    border: 1px solid var(--calenso-hawkes-blue-color);
    background: var(--calenso-card-background-color);
    box-shadow: var(--calenso-box-shadow);
    border-radius: var(--calenso-border-radius);
    -webkit-border-radius: var(--calenso-border-radius);
    -moz-border-radius: var(--calenso-border-radius);

    @media not all and (min-width: 639px) {
      box-shadow: none;
      border-left: none;
      border-right: none;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
    }
  }
}

@layer utilities {
  .cbp-danger {
    @apply cbp-bg-red-100 cbp-border-red-500 cbp-text-red-700;
  }

  .cbp-warning {
    @apply cbp-bg-orange-100 cbp-border-orange-500 cbp-text-orange-700;
  }

  .cbp-info {
    @apply cbp-bg-blue-100 cbp-border-blue-500 cbp-text-blue-700;
  }
}

.cbcp-language-list.mat-mdc-menu-panel {
  --mat-menu-container-shape: 8px;
  .mat-mdc-menu-item {
    min-height: 32px;
  }
}
