:root {
  // Color variables
  --calenso-primary-color: #3A4A66;
  --calenso-black-color: #000000;
  --calenso-white-color: #FFFFFF;
  --calenso-light-sky-blue: #EBF1FB;
  --calenso-medium-grey-color: #E7E7E7;
  --calenso-hawkes-blue-color: #DFE1E9;
  --calenso-solitude-color: #F4F6FA;
  --calenso-bali-hai-color: #909AAF;
  --calenso-overlay-color: #3A4A6661;
  --calenso-skeleton-bg-color: #E2E8F0;
  --calenso-neon-blue-color: #5555FF;

  // Dynamic variables
  --calenso-font-color: var(--calenso-black-color);
  --calenso-box-shadow: none;
  --calenso-border-radius: 0px;
  --calenso-body-background-color: var(--calenso-light-sky-blue);
  --calenso-card-background-color: var(--calenso-white-color);
  --calenso-header-background-color: var(--calenso-white-color);
}
